<template>
  <v-form ref="loginForm" @submit.prevent="send">
    <v-card-text>
      <v-text-field
        v-model="credential.userLogin"
        label="Login"
        :rules="[(v) => !!v || 'Requis']"
        prepend-icon="person"
        type="text"
        outlined
      />
      <v-text-field
        v-model="credential.password"
        label="password"
        :rules="[(v) => !!v || 'Requis']"
        prepend-icon="password"
        type="password"
        outlined
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading"
        color="accent"
        type="submit"
        v-text="'Login'"
      />
      <v-spacer />
    </v-card-actions>
  </v-form>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    credential: {
      userLogin: '',
      password: '',
    },
  }),
  methods: {
    send () {
      if (this.$refs.loginForm.validate()) {
        this.$emit('send', this.credential)
      }
    },
  },
}
</script>
